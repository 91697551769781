<template>
  <div class="inline-block relative overflow-hidden mask">
    <div ref="tape" class="inline-block relative t-0 tape">
      <div v-if="hasStarted" class="line">
        {{ next }}
      </div>
      <div v-else class="line">
        {{ current }}
      </div>
      <div class="line">
        {{ current }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      lines: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        currentIndex: 0,

        // This signifies whether animation has started. This is needed since we
        // need the current and next external services to be displayed
        // differently upon first load vs. once animation has started
        hasStarted: false,
      }
    },

    computed: {
      current() {
        return this.lines[this.currentIndex]
      },

      next() {
        let nextIndex = this.currentIndex + 1
        if (nextIndex > this.lines.length - 1) nextIndex = 0

        return this.lines[nextIndex]
      },
    },

    mounted() {
      this.$refs.tape.addEventListener('animationstart', this.start, false)
      this.$refs.tape.addEventListener('animationiteration', this.move, false)
    },

    methods: {
      start() {
        this.hasStarted = true
      },

      move() {
        this.currentIndex = (this.currentIndex >= this.lines.length - 1) ? 0 : this.currentIndex + 1
      },
    },
  }
</script>

<style lang="scss" scoped>
  .mask {
    height: 44px;
  }

  .line {
    @apply whitespace-no-wrap;
  }

  .tape {
    animation: move 1.25s infinite;
    animation-delay: 1.75s;
  }

  @keyframes move {
    // We use translate instead of offsets to take advantage of GPU for silky smooth animations
    0% { transform: translateY(-40px); }
    7% { transform: translateY(0); }
  }
</style>
